// @ts-nocheck
import OrderDetailController from "./OrderDetailController.web";

import * as React from "react";
import { withRouter } from "react-router";
import withLoader from "../../studio-store-ecommerce-components/src/HOC/withBrandingSpinner.web";
import withHeadeActionBar from "../../studio-store-ecommerce-components/src/HOC/withHeadeActionBar.Web";
import withDialog from "../../studio-store-ecommerce-components/src/HOC/withDialog.web";
import { withDialog as withBasicDialog } from "../../studio-store-ecommerce-components/src/Dialog/withDialog";
import {
  withStyles,
  Theme,
  createStyles,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
  ListItemSecondaryAction,
  MenuItem,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import "./order.css";
import EditAddress from "./EditAddress.web";
import OpenInNewOutlinedIcon from "@material-ui/icons/OpenInNewOutlined";
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";
import OrderDetailProidver from "../../studio-store-ecommerce-components/src/Dialog/DialogContext";
import { Scrollbars } from "react-custom-scrollbars";
import { PackagingDetails } from "./Utilities/PackagingDetails";
import TextField from '../../studio-store-ecommerce-components/src/TextField/TextField.web'
import { capitalize } from "../../../framework/src/utils/Utils";

const configJSON = require("./config");

const StyledTableCell = withStyles(() =>
  createStyles({
    head: {
      backgroundColor: "transparent",
      color: "#9B9FB1",
      fontWeight: 400,
      fontSize: 10,
      textTransform: "uppercase",
      letterSpacing: "0.05em",
      border: "none",
      whiteSpace: (props) => (props.oneRow ? "nowrap" : undefined),
    },
    body: {
      fontSize: 12,
      border: "none",
      color: "#000",
      fontWeight: 400,
    },
  })
)(TableCell);

const Delivery = (props: any) => {
  const openEditDeliveryAddressDialog = async (delivery_addresses) => {
    const dialogData = await props
      .openDialogBox({
        width: "684px",
        renderedComponent: EditAddress,
        title: configJSON.editDeliveryAddress,
        titlePadding: "40px",
        dataToPass: {
          delivery_addresses: delivery_addresses,
        },
        disableBackdropClick: true,
        disableEscapeKeyDown: true,
        withCustomDialog: false,
      })
    if (dialogData) {
      props.updateDeliveryAddress(dialogData);
    }
  };
  return (
    <Grid item xs={12}>
      <div
        data-testid="edit-address-button"
        className="edit-address-button"
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => {
          openEditDeliveryAddressDialog(props.delivery_addresses);
        }}
      >
        <OpenInNewOutlinedIcon fontSize="14px" />
        <span>{configJSON.editAddress}</span>
      </div>
    </Grid>
  );
};
const DeliveryAddress = withDialog(Delivery);
export class OrderDetail extends OrderDetailController {

  handleCountryCodes = (code: any) => {
    if (code === 'uk') {
      return 'United Kingdom'
    }
    if (code === 'ae') {
      return 'United Arab Emirates'
    }
    if (code === 'us') {
      return 'United State'
    } else {
      return capitalize(code)
    }

  }

  shipOrderIsValid = () => {
    let shipOrderIsValidBoolean;
    if (this.state.brandingData?.attributes?.country === 'United Kingdom') {
      shipOrderIsValidBoolean = this.state.orderDetail?.stuart_tracking_url !== null ? true : false
    } else {
      shipOrderIsValidBoolean = this.handleShipOrder()
    }
    return shipOrderIsValidBoolean
  }
  render() {
    const { classes } = this.props;
    const { orderDetail } = this.state;

    return (
      <OrderDetailProidver>
        {orderDetail ? (
          <Scrollbars>
            <div id='order-detail-container' className={classes.orderdetailContainer}>
              <Grid container className={classes.boxContainer} spacing={2}>
                <Grid item xs={12}>
                  <div id='header-order-detail-title' style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flex: 1 }}>
                      <Typography
                        className={classes.backButton}
                        onClick={() => {
                          this.props.history.push("/orders");
                        }}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: 4,
                        }}
                        component="div"
                      >
                        <ArrowBackOutlinedIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            this.props.history.push("/orders");
                          }}
                          fontSize="small"
                        />
                        {configJSON.backButton}
                      </Typography>
                      <Typography className={classes.pageHeading}>
                        {configJSON.orderDetails}
                      </Typography>
                    </div>
                    <div>
                      <Button
                        variant="contained"
                        onClick={() => {
                          window.open(
                            this.state.orderDetail?.pdf_invoice_url,
                            "_blank"
                          );
                        }}
                        color="primary"
                      >
                        {configJSON.downloadInvoice}
                      </Button>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} className="mt-24">
                  <Grid container spacing={2} justifyContent="space-between">
                    <Grid item className={classes.leftContainer}>
                      <div className={classes.cardContainer}>
                        <Grid
                          container
                          spacing={2}
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid item xs={6}>
                            <ListItem disableGutters>
                              <ListItemText
                                classes={{
                                  primary: classes.listPrimaryText1,
                                  secondary: classes.listSecondaryText1,
                                }}
                                primary={configJSON.orderNumber}
                                secondary={orderDetail?.order_number}
                              />
                            </ListItem>
                          </Grid>
                          <Grid item xs={6}>
                            <ListItem disableGutters>
                              <ListItemText
                                classes={{
                                  primary: classes.listPrimaryText1,
                                  secondary: classes.listSecondaryText1,
                                }}
                                primary={configJSON.orderTotal}
                                secondary={withCurrency(orderDetail?.total)}
                              />
                            </ListItem>
                          </Grid>
                          <Grid item xs={6}>
                            <ListItem disableGutters>
                              <ListItemText
                                classes={{
                                  primary: classes.listPrimaryText1,
                                  secondary: classes.listSecondaryText1,
                                }}
                                primary={configJSON.orderDate}
                                secondary={orderDetail?.order_date}
                              />
                            </ListItem>
                          </Grid>
                          <Grid item xs={6}>
                            <ListItem disableGutters>
                              <ListItemText
                                classes={{
                                  primary: classes.listPrimaryText1,
                                  secondary: classes.listSecondaryText1,
                                }}
                                primary={configJSON.customer}
                                secondary={
                                  orderDetail?.account?.data?.attributes
                                    ?.full_name
                                }
                              />
                            </ListItem>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                    <Grid item className={classes.rightContainer}>
                      <div
                        className={classes.cardContainer}
                        style={{ height: "100%" }}
                      >
                        <Grid
                          container
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={2}
                        >
                          <Grid item xs={12}>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div style={{ flex: 1 }}>
                                <Typography className={classes.boldHeading}>
                                  {configJSON.statusLabel}
                                </Typography>
                              </div>
                              <div className={classes.statusBox}>
                                {this.getOrderStatusText(
                                  this.state.orderDetail?.status
                                )}
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              select
                              fullWidth
                              width={"100%"}
                              required
                              name="status"
                              label={"Order status"}
                              placeholder="Order status"
                              onChange={this.handleChangeStatus}
                              value={this.state.orderStatus}
                            >
                              {this.handleOrderStatus().map((item) => {
                                return (
                                  <MenuItem key={item} value={item}>
                                    {this.getOrderStatusText(item)}
                                  </MenuItem>
                                );
                              })}
                            </TextField>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={2}
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <Grid item className={classes.leftContainer}>
                      <div className={classes.cardContainer}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography className={classes.boldHeading}>
                              {configJSON.itemsLabel}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <TableContainer>
                              <Scrollbars
                                autoHeight
                                autoHeightMin={260}
                                autoHeightMax={10000}
                              >
                                <Table
                                  className={classes.table}
                                  aria-label="customized table"
                                >
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableCell style={{ width: "59%" }}>
                                        {configJSON.itemLabel}
                                      </StyledTableCell>
                                      <StyledTableCell align="left" oneRow>
                                        {configJSON.quantityLabel}
                                      </StyledTableCell>
                                      <StyledTableCell align="left" oneRow>
                                        {configJSON.unitPriceLabel}
                                      </StyledTableCell>
                                      <StyledTableCell align="left" oneRow>
                                        {configJSON.totalPriceLabel}
                                      </StyledTableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {orderDetail?.order_items?.data.map(
                                      (order) => (
                                        <TableRow key={order.id}>
                                          <StyledTableCell
                                            style={{ minWidth: "360px" }}
                                          >
                                            <Grid
                                              container
                                              alignItems="center"
                                              justifyContent="space-between"
                                            >
                                              <Grid item xs={4}>
                                                <div
                                                  style={{
                                                    width: "128px",
                                                    height: "152px",
                                                  }}
                                                >
                                                  <img
                                                    style={{
                                                      objectFit: "cover",
                                                      objectPosition: "center",
                                                      width: "100%",
                                                      height: "100%",
                                                    }}
                                                    src={
                                                      this.getImageUrl(
                                                        order?.attributes
                                                      ) ||
                                                      "https://images.unsplash.com/photo-1493612276216-ee3925520721?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=464&q=80"
                                                    }
                                                  />
                                                </div>
                                              </Grid>
                                              <Grid item xs={7}>
                                                <Grid
                                                  container
                                                  justifyContent="space-between"
                                                  alignItems="center"
                                                >
                                                  <Grid item xs={12}>
                                                    <Typography>
                                                      {
                                                        order?.attributes
                                                          ?.catalogue?.data
                                                          ?.attributes?.name
                                                      }
                                                    </Typography>
                                                  </Grid>
                                                  {order?.attributes?.catalogue_variants.map(
                                                    (variant) => {
                                                      return (
                                                        <Grid item xs={5}>
                                                          <ListItem
                                                            disableGutters
                                                          >
                                                            <ListItemText
                                                              classes={{
                                                                primary:
                                                                  classes.listPrimaryText,
                                                                secondary:
                                                                  classes.listSecondaryText,
                                                              }}
                                                              primary={`${Object.keys(
                                                                variant
                                                              )[0]
                                                                }`}
                                                              secondary={`${Object.values(
                                                                variant
                                                              )[0]
                                                                }`}
                                                            />
                                                          </ListItem>
                                                        </Grid>
                                                      );
                                                    }
                                                  )}
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </StyledTableCell>
                                          <StyledTableCell align="left">
                                            {order?.attributes?.quantity}
                                          </StyledTableCell>
                                          <StyledTableCell align="left">
                                            {withCurrency(
                                              order?.attributes?.unit_price
                                            )}
                                          </StyledTableCell>
                                          <StyledTableCell align="left">
                                            {withCurrency(
                                              order?.attributes?.total_price
                                            )}
                                          </StyledTableCell>
                                        </TableRow>
                                      )
                                    )}
                                  </TableBody>
                                </Table>
                              </Scrollbars>
                            </TableContainer>
                          </Grid>
                          <Grid xs={12} item>
                            <Divider flexItem style={{ background: "#000" }} />
                          </Grid>
                          <Grid xs={12} item>
                            <Grid container>
                              <Grid xs={7} item />
                              <Grid xs={5} item>
                                <Grid container spacing={1}>
                                  <Grid xs={12} item>
                                    <List dense={true}>
                                      <ListItem>
                                        <ListItemText
                                          classes={{
                                            primary: classes.subTotal,
                                          }}
                                          primary={configJSON.paymentMethodLabel}
                                        />
                                        <ListItemSecondaryAction
                                          classes={{ root: classes.subTotal }}
                                        >
                                            {this.state.orderDetail.source}
                                        </ListItemSecondaryAction>
                                      </ListItem>
                                      <ListItem>
                                        <ListItemText
                                          classes={{
                                            primary: classes.subTotal,
                                          }}
                                          primary={configJSON.subTotalLabel}
                                        />
                                        <ListItemSecondaryAction
                                          classes={{ root: classes.subTotal }}
                                        >
                                          {withCurrency(
                                            this.state.orderDetail.sub_total
                                          )}
                                        </ListItemSecondaryAction>
                                      </ListItem>
                                      <ListItem>
                                        <ListItemText
                                          classes={{
                                            primary: classes.subTotal,
                                          }}
                                          primary={configJSON.couponCodeLabel}
                                        />
                                        <ListItemSecondaryAction
                                          classes={{ root: classes.subTotal }}
                                        >
                                          {this.state.orderDetail.coupon_code ||
                                            "-"}
                                        </ListItemSecondaryAction>
                                      </ListItem>
                                      <ListItem>
                                        <ListItemText
                                          classes={{
                                            primary: classes.subTotal,
                                          }}
                                          primary={configJSON.deliveryChargesLabel}
                                        />
                                        <ListItemSecondaryAction
                                          classes={{ root: classes.subTotal }}
                                        >
                                          {withCurrency(
                                            this.state.orderDetail.shipping_charge
                                          )}
                                        </ListItemSecondaryAction>
                                      </ListItem>
                                      <ListItem>
                                        <ListItemText
                                          classes={{
                                            primary: classes.subTotal,
                                          }}
                                          primary={configJSON.appliedDiscountLabel}
                                        />
                                        <ListItemSecondaryAction
                                          classes={{ root: classes.subTotal }}
                                        >
                                          {withCurrency(
                                            this.state.orderDetail
                                              .applied_discount
                                          )}
                                        </ListItemSecondaryAction>
                                      </ListItem>
                                      <ListItem>
                                        <ListItemText
                                          classes={{
                                            primary: classes.subTotal,
                                          }}
                                          primary={configJSON.taxesLabel}
                                        />
                                        <ListItemSecondaryAction
                                          classes={{ root: classes.subTotal }}
                                        >
                                          {withCurrency(
                                            this.state.orderDetail
                                              .total_tax
                                          )}
                                        </ListItemSecondaryAction>
                                      </ListItem>
                                    </List>
                                  </Grid>
                                  <Grid xs={12} item>
                                    <List dense={true}>
                                      <ListItem>
                                        <ListItemText
                                          classes={{ primary: classes.total }}
                                          primary={configJSON.totalLabel}
                                        />
                                        <ListItemSecondaryAction
                                          classes={{ root: classes.total }}
                                        >
                                          {withCurrency(
                                            this.state.orderDetail.total
                                          )}
                                        </ListItemSecondaryAction>
                                      </ListItem>
                                    </List>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                    <Grid item className={classes.rightContainer}>
                      <div className={classes.cardContainer}>
                        <Grid
                          container
                          spacing={1}
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid item xs={12}>
                            <Typography className={classes.boldHeading}>
                              {configJSON.deliveryAddress}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography className={classes.address}>
                              {this.state.delivery_addresses?.name || ""}
                            </Typography>
                            <Typography className={classes.address}>
                              {`${this.state.delivery_addresses?.flat_no || ""
                                } ${this.state.delivery_addresses?.address || ""
                                } ${this.state.delivery_addresses?.address_line_2 ||
                                ""
                                }`}
                            </Typography>
                            <Typography className={classes.address}>
                              {this.state.delivery_addresses?.city || ""}
                            </Typography>
                            <Typography className={classes.address}>
                              {this.state.delivery_addresses?.state || ""}
                            </Typography>
                            <Typography className={classes.address}>
                              {this.handleCountryCodes(this.state.delivery_addresses?.country)}
                            </Typography>
                            <Typography className={classes.address}>
                              {this.state.delivery_addresses?.zip_code || ""}
                            </Typography>
                            <Typography className={classes.address}>
                              {this.state.delivery_addresses?.phone_number ||
                                ""}
                            </Typography>
                          </Grid>
                          <DeliveryAddress
                            delivery_addresses={this.state.delivery_addresses}
                            updateDeliveryAddress={this.updateDeliveryAddress.bind(
                              this
                            )}
                          />
                          <Button
                            className={classes.shipOrderButton}
                            onClick={() =>  this.handleClickShipingOrder(this.state.brandingData?.attributes)}
                            disabled={this.shipOrderIsValid()}
                          >
                            {configJSON.shipOrder}
                          </Button>


                          {this.state.orderDetail?.stuart_tracking_url !== null ?
                            <Button
                              className={classes.trackShipOrderButton}
                              onClick={() => this.handleTrackShipOrder(this.state.orderDetail?.stuart_tracking_url)}

                            >
                              {configJSON.trackShipOrder}
                            </Button>
                            : null}
                        </Grid>



                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <PackagingDetails
                  classes={classes}
                  initialValues={this.state.packagingDetails}
                  onSubmit={this.formSubmit}
                  packageOptions={this.state.packageOptions}
                  formRef={this.formRef}
                  showHeaderBar={this.openToastOnChange}
                  country={this.state.brandingData?.attributes?.country}
                />
              </Grid>
            </div>
          </Scrollbars>
        ) : null}
      </OrderDetailProidver>
    );
  }
}

const OrderDetailComponentWithRouter = withRouter(OrderDetail);
export const styles = (theme: Theme) =>
  createStyles({
    orderdetailContainer: {
      background: "#f9f8fa",
    },
    boxContainer: {
      padding: "40px 24px",
      width: "100%",
    },
    leftContainer: {
      width: "calc(100% - 330px)",
    },
    rightContainer: {
      width: 330,
    },
    cardContainer: {
      background: "#fff",
      padding: "40px 43px",
      border: "1px solid #ECEDF0",
      borderRadius: "3px",
    },
    listPrimaryText: {
      fontSize: "12px",
      fontWeight: 400,
      color: "#9B9FB1",
      lineHeight: "20px",
    },
    listSecondaryText: {
      fontSize: "12px",
      fontWeight: 500,
      color: "#000000",
      lineHeight: "20px",
    },
    statusBox: {
      background: "#F7F5FF",
      border: "1px solid #BCB1F5",
      borderRadius: "32px",
      padding: "2px 8px",
      textAlign: "center",
      color: "#4F31E4",
    },
    backButton: {
      color: "#676B7E",
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "22px",
      cursor: "pointer",
    },
    pageHeading: {
      color: "#3C3E49",
      fontSize: "24px",
      fontWeight: 500,
      lineHeight: "28px",
      letterSpacing: "0.02em",
    },
    listPrimaryText1: {
      fontSize: "10px",
      fontWeight: 400,
      color: "#9B9FB1",
      lineHeight: "20px",
      textTransform: "uppercase",
    },
    listSecondaryText1: {
      fontSize: "20px",
      fontWeight: 500,
      color: "#3C3E49",
    },
    address: {
      fontSize: "12px",
      color: "#000",
      lineHeight: "20px",
      fontWeight: 400,
    },
    editAddress: {
      fontSize: "14px",
      fontWeight: 400,
      color: "#000",
      lineHeight: "20px",
      cursor: "pointer",
    },
    shipOrderButton: {
      flexGrow: "1",
      color: "#3C3E49",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "20px",
      background: "#00D659",
      borderRadius: "3px",
      textTransform: "none",
      marginTop: 40,
    },

    trackShipOrderButton: {
      flexGrow: "1",
      color: "#3C3E49",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "20px",
      background: "#00D659",
      borderRadius: "3px",
      textTransform: "none",
      marginTop: 10,
      width: '100%'
    },
    extraText: {
      color: "#83889E",
      fontWeight: 400,
      fontSize: "10px",
    },
    boldHeading: {
      color: "#3C3E49",
      fontWeight: 500,
      fontSize: "20px",
    },
    subTotal: {
      fontSize: "12px",
      fontWeight: 400,
      color: "#000",
      lineHeight: "20px",
    },
    total: {
      fontSize: "14px",
      fontWeight: 500,
      color: "#000",
      lineHeight: "20px",
    },
    helperTextStyle: {
      marginLeft: 0,
      fontSize: "10px",
      lineHeight: "16px",
      color: "#83889E",
    },
  });

const withCurrency = (value: any) => {
  if (value === undefined || value === null) {
    return "-";
  }
  let price = parseFloat(value).toFixed(2);
  return `${window.localStorage.getItem("currency_type") || ""}${price}`
};

export default withBasicDialog(
  withStyles(styles)(
    withLoader(withHeadeActionBar(OrderDetailComponentWithRouter))
  )
);

import React, { Component, Suspense, lazy } from "react";
import { Router, Route, Switch } from "react-router-dom";
import Layout from "../../studio-store-ecommerce-components/src/Layout";
import ImageCatalogueBulkUpload from "../../admin-image-catalogue/src/ImageCatalogueBulkUpload/ImageCatalogueBulkUpload.web";
import EmailTemplates from "../../admin-email-templates/src/EmailTemplates.web";
import EmailTemplatesCreate from "../../admin-email-templates/src/EmailTemplatesCreate/EmailTemplatesCreate.web";
import Payment from "../../admin-payment/src/Payment.web";
import Notifications from "../../admin-notifications/src/Notifications.web";
import MobileApp from "../../admin-mobile-app/src/MobileApp.web";
import UserConfirmation from '../../studio-store-ecommerce-components/src/UserConfirmation/UserConfirmation'
import GlobalLoading from '../../studio-store-ecommerce-components/src/GlobalLoading/GlobalLoading'
import { createBrowserHistory } from "history";

let STORE_BASE_PATH_NAME = "/";
try {
  STORE_BASE_PATH_NAME =
    new URL(`${process.env.REACT_APP_BASE_URL}`).pathname ?? "";
} catch {}

const history = createBrowserHistory({
  basename: STORE_BASE_PATH_NAME,
  getUserConfirmation: (message, callback) =>
    UserConfirmation(message, callback),
});

declare global {
  interface Window {
    analytics: any;
  }
};

const ImageCatalogue: any = lazy(
  () => import("../../admin-image-catalogue/src/ImageCatalogue.web")
);

const Dashboard: any = lazy(
  () => import("../../admin-dashboard/src/Dashboard")
);
const BrandingHome: any = lazy(
  () => import("../../admin-brand-settings/src/Branding")
);

import Login from "../../email-account-login/src/Login.web";
import ForgotPassword from "../../email-account-login/src/ForgotPassword.web";
import ProtectedRoute from "./protectedRoute.web";

const CreateStaticPage: any = lazy(
  () => import("../../admin-static-page/src/CreateStatic/CreateStatic")
);

const Products: any = lazy(
  () => import("../../admin-products/src/Products.web")
);
const AddProduct: any = lazy(
  () => import("../../admin-products/src/AddProduct/AddProduct")
);
const Customers: any = lazy(
  () => import("../../admin-customers/src/Customers.web")
);
const AddCustomers: any = lazy(
  () =>
    import("../../admin-customers/src/CustomersCreate/CustomersCreate.web")
);
const CustomersInboundQueries: any = lazy(
  () =>
    import("../../admin-customers/src/CustomersInboundQueries/CustomersInboundQueries.web")
);
const CustomersReviews: any = lazy(
  () =>
    import("../../admin-customers/src/CustomersReviews/CustomersReviews.web")
);
const PromoCode: any = lazy(
  () => import("../../admin-promo-code/src/PromoCode.web")
);
const AddPromoCode: any = lazy(
  () =>
    import("../../admin-promo-code/src/CreatePromoCode/PromoCodeCreate.web")
);
const StoreDetails: any = lazy(() =>
  import("../../admin-store-details/src/StoreDetails.web")
);
import OrderListComponent from "../../admin-orders/src/Orders.web";
import OrderDetail from "../../admin-orders/src/OrderDetail.web";
const StaticPageTable: any = lazy(
  () => import("../../admin-static-page/src/StaticTable/StaticPageTable")
);
const Categories: any = lazy(
  () => import("../../admin-categories/src/categories/Categories")
);
const AddCategory: any = lazy(
  () => import("../../admin-categories/src/addCategory/AddCategory")
);
const TaxesWeb: any = lazy(
  () => import("../../admin-taxes/src/Taxes.web")
);
const ShippingWeb: any = lazy(
  () => import('../../admin-shipping/src/Shipping.web'))

const BrandsWeb: any = lazy(
  () => import("../../admin-brands/src/Brands.web")
);

const BulkUploadsWeb: any = lazy(
  () => import("../../admin-bulk-uploads/src/BulkUploads.web")
);

const BulkUploadCategoryDetailWeb: any = lazy(
  () => import("../../admin-bulk-uploads/src/BulkUploadCategoryDetail.web")
);

const VariantsWeb: any = lazy(
  () => import("../../admin-variants/src/Variants.web")
);

const Account: any = lazy(() => import("../../admin-account/src/Account.web"));
const AccountDetails: any = lazy(
  () => import("../../admin-account/src/AccountDetails/AccountDetails.web")
);
const AdminUsers: any = lazy(
  () => import("../../admin-account/src/AdminUsersList/AdminUsersList.web")
);
const SubAdminUsers: any = lazy(
  () => import("../../admin-account/src/SubAdminUsers/SubAdminUsers.web")
);
const CustomerFeedback: any = lazy(
  () => import("../../admin-customer-feedback/src/CustomerFeedback.web")
);
const AddCustomerFeedback: any = lazy(
  () => import("../../admin-customer-feedback/src/CreateCustomerFeedback/CustomerFeedbackCreate.web")
);
const HomePage: any = lazy(
  () => import("../../admin-dashboard/src/Home")
);
const AccountPlans: any = lazy(
  () => import("../../admin-account/src/AccountPlans/AccountPlans.web")
);
const OtherIntegrations: any = lazy(
  () => import("../../admin-other-integrations/src/OtherIntegrations.web")
);
class WebRoutes extends Component {
  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route path="/login" exact component={Login} />
          <Route path="/forgotPassword" exact component={ForgotPassword} />
          <Suspense fallback={<GlobalLoading />}>
            <Layout>
              <ProtectedRoute
                path="/website-and-emails/branding"
                exact
                component={BrandingHome}
              />
              <ProtectedRoute
                path="/website-and-emails/static-pages"
                exact
                component={StaticPageTable}
              />
              <ProtectedRoute
                path="/website-and-emails/static-pages/create"
                exact
                component={CreateStaticPage}
              />
              <ProtectedRoute
                path="/website-and-emails/static-pages/edit/:id"
                exact
                component={CreateStaticPage}
              />

              <ProtectedRoute
                path="/website-and-emails/image-catalogue"
                exact
                component={ImageCatalogue}
              />
              <ProtectedRoute
                path="/website-and-emails/image-catalogue/bulk-upload"
                exact
                component={ImageCatalogueBulkUpload}
              />
              <ProtectedRoute
                path="/website-and-emails/email-templates"
                exact
                component={EmailTemplates}
              />
              <ProtectedRoute path="/categories" exact component={Categories} />
              <ProtectedRoute
                path="/addCategory/:id?"
                exact
                component={AddCategory}
              />
              <ProtectedRoute
                path="/website-and-emails/email-templates/:id"
                exact
                component={EmailTemplatesCreate}
              />
              <ProtectedRoute path="/products" exact component={Products} />
              <ProtectedRoute
                path="/products/add-product/:id?"
                exact
                component={AddProduct}
              />
              <ProtectedRoute
                exact
                path="/orders"
                component={OrderListComponent}
              />
              <ProtectedRoute
                exact
                path="/orders/orderDetail/:id"
                component={OrderDetail}
              />
              <ProtectedRoute exact path="/customers" component={Customers} />
              <ProtectedRoute
                exact
                path="/customers/add-customer/:id?"
                component={AddCustomers}
              />
              <ProtectedRoute
                exact
                path="/customers/inbound-queries"
                component={CustomersInboundQueries}
              />
              <ProtectedRoute
                exact
                path="/customers/reviews"
                component={CustomersReviews}
              />
              <ProtectedRoute
                exact
                path="/business-settings/promo-code"
                component={PromoCode}
              />
              <ProtectedRoute
                exact
                path="/business-settings/payment"
                component={Payment}
              />
              <ProtectedRoute
                exact
                path="/business-settings/notifications"
                component={Notifications}
              />
              <ProtectedRoute
                exact
                path="/business-settings/mobile-app"
                component={MobileApp}
              />
              <ProtectedRoute
                exact
                path="/business-settings/promo-code/create/:id?"
                component={AddPromoCode}
              />
              <ProtectedRoute
                exact
                path="/business-settings/taxes"
                component={TaxesWeb}
              />
              <ProtectedRoute
                exact
                path="/business-settings/shipping"
                component={ShippingWeb}
              />


              <ProtectedRoute
                exact
                path="/business-settings/store-details"
                component={StoreDetails}
              />
              <ProtectedRoute
                exact
                path="/inventory/brands"
                component={BrandsWeb}
              />
              <ProtectedRoute
                exact
                path="/inventory/bulk-uploads"
                component={BulkUploadsWeb}
              />
              <ProtectedRoute
                exact
                path="/inventory/bulk-uploads/upload-product/:id?"
                component={BulkUploadCategoryDetailWeb}
              />
              <ProtectedRoute
                exact
                path="/inventory/variants"
                component={VariantsWeb}
              />
              <ProtectedRoute
                path="/inventory/categories"
                exact
                component={Categories}
              />
              <ProtectedRoute
                path="/inventory/addCategory/:id?"
                exact
                component={AddCategory}
              />
              <ProtectedRoute exact path="/account/overview" component={Account} />
              <ProtectedRoute
                exact
                path="/account/detail/"
                component={AccountDetails}
              />
              <ProtectedRoute
                exact
                path="/account/admin-users/"
                component={AdminUsers}
              />
              <ProtectedRoute
                exact
                path="/account/admin-users/:id"
                component={SubAdminUsers}
              />
              <ProtectedRoute
                exact
                path="/website-and-emails/customer-feedback"
                component={CustomerFeedback}
              />
              <ProtectedRoute
                exact
                path="/website-and-emails/customer-feedback/create/:id?"
                component={AddCustomerFeedback}
              />
              <ProtectedRoute
                exact
                path="/account/plan"
                component={AccountPlans}
              />
              <ProtectedRoute
                exact
                path="/business-settings/other-integrations"
                component={OtherIntegrations}
              />

              <ProtectedRoute path="/" exact component={Dashboard} />
              <ProtectedRoute path="/dashboard" exact component={HomePage} />
            </Layout>
          </Suspense>
        </Switch>
      </Router>
    );
  }
}

export default WebRoutes;

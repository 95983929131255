import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { withLoaderProps } from "../../studio-store-ecommerce-components/src/HOC/withBrandingSpinner.web";
import { RouterProps } from "react-router";
import { dialogBoxProps } from "../../studio-store-ecommerce-components/src/HOC/withDialog.web";
// Customizable Area End
import moment from "moment";
import { withHeadeActionBarProps } from "../../studio-store-ecommerce-components/src/HOC/withHeadeActionBar.Web";
import { withToastProps } from "../../studio-store-ecommerce-components/src/HOC/withSnackBar.Web";
const configJSON = require("./config");

export type Props = RouterProps &
  withLoaderProps &
  withHeadeActionBarProps &
  withToastProps &
  dialogBoxProps & {
    id: string;
    // Customizable Area Start
    classes: any;
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  orderList: any[];
  orderStatus: string;
  page: number;
  rowPerPage: number;
  orderFilters: any;
  totalOrders: number;
  totalPlacedOrders: number;
  totalDilveredOrder: number;
  totalRefundedOrder: number;
  totalCanceledOrder: number;
  allOrderCount: number;
  isFirstLoad: boolean;
  isSearchHandled: boolean;
  queryParrm: string;
  checkedOrdersList: any;
  searchValue: string;
  showEmpty: boolean;
  anchorEl: HTMLButtonElement | null;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class OrdersController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  ordersDataApiCallId: string = "";
  downloadordersDataApiCallId: string = "";
  postBulkDeleteCallId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.AlertMessage)];
    this.state = {
      orderList: [],
      orderStatus: "all",
      page: 1,
      rowPerPage: 30,
      orderFilters: {
        fromDate: "",
        toDate: "",
        isPlaced: false,
        isDelivered: false,
        isRefunded: false,
        isCancelled: false,
        total: ""
      },
      totalOrders: 0,
      totalPlacedOrders: 0,
      totalDilveredOrder: 0,
      totalRefundedOrder: 0,
      totalCanceledOrder: 0,
      allOrderCount: 0,
      isFirstLoad: true,
      isSearchHandled: false,
      queryParrm: "",
      checkedOrdersList: [],
      searchValue: "",
      showEmpty: false,
      anchorEl: null
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getOrders();
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      var errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if (this.parseExpireTokenResponse(responseJson, this.state, this.props)) {
        if (apiRequestCallId !== null) {
          if (apiRequestCallId === this.ordersDataApiCallId) {
            this.props.hideLoader();
            if (responseJson?.data) {
              let orderResponse = responseJson?.data;
              const orderList = [];
              const allOrderCount = responseJson?.meta?.pagination?.total_count || 0;
              this.setOrdersCount(responseJson?.meta?.pagination);
              for (const order of orderResponse) {
                orderList.push({
                  id: order?.id,
                  orderNumber: order?.attributes?.order_number,
                  orderDate: order?.attributes?.order_date,
                  customer: {
                    name: order?.attributes?.account?.data?.attributes?.full_name,
                  },
                  status: { status: order?.attributes?.status },
                  total: this.withCurrency(order?.attributes?.total),
                  download: order?.attributes?.pdf_invoice_url,
                });
              }
              this.setState({
                orderList,
                isFirstLoad: false,
                allOrderCount: allOrderCount,
                showEmpty: allOrderCount > 0 ? false : true,
              });
              this.setOrdersCount(responseJson?.meta?.pagination)
            } else {
              this.handleErrorResponse(responseJson);
            }
          } else if (this.downloadordersDataApiCallId === apiRequestCallId) {
            const rows = responseJson.csv_data;
            let csvContent = "data:text/csv;charset=utf-8,";

            rows.forEach(function (rowData: any) {
              let rowOfArray = rowData.join(",");
              csvContent += rowOfArray + "\r\n";
            });

            const encodedUri = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "orders_data.csv");
            document.body.appendChild(link);

            link.click();

            this.props.hideLoader();
          } else if (this.postBulkDeleteCallId === apiRequestCallId) {
            this.props.hideLoader();
            if (responseJson && responseJson?.message) {
              this.props.showHeaderBar({ type: "success" });
              this.getOrders();
            } else if (responseJson?.errors) {
              return this.props.showHeaderBar({ message: configJSON.somethingWentWrong, type: "error" });
            }
          }
        }
      } else {
        this.logoutAndRedirectToLoginPage(this.props);
      }
    }
    // Customizable Area End
  }

  withCurrency = (value: any) => {
    if (value === undefined || value === null) {
      return "-";
    }
    let price = parseFloat(value).toFixed(2);
    return `${window.localStorage.getItem("currency_type") || ""}${price}`
  };

  handleErrorResponse = (responseJson: any) => {
    let errorMessage = this.parseApiErrorResponse(responseJson);
    this.props.showHeaderBar({
      message: errorMessage,
      type: "error"
    });
  };

  setOrdersCount = (pagination: any) => {
    const { isPlaced, isDelivered, isRefunded, isCancelled } = this.state.orderFilters;

    if (isPlaced || this.state.orderStatus === "all" || this.state.orderStatus === "placed") {
      this.setState({
        totalOrders:
          ((isPlaced || this.state.orderStatus === "placed") &&
            this.state.orderStatus !== "all" &&
            pagination?.placed_orders_count) ||
          0,
        totalPlacedOrders: pagination?.placed_orders_count || 0
      });
    }
    if (isDelivered || this.state.orderStatus === "all" || this.state.orderStatus === "delivered") {
      this.setState({
        totalOrders:
          ((isDelivered || this.state.orderStatus === "delivered") &&
            this.state.orderStatus !== "all" &&
            pagination?.deliverd_orders_count) ||
          0,
        totalDilveredOrder: pagination?.deliverd_orders_count || 0
      });
    }
    if (isRefunded || this.state.orderStatus === "all" || this.state.orderStatus === "refunded") {
      this.setState({
        totalOrders:
          ((isRefunded || this.state.orderStatus === "refunded") &&
            this.state.orderStatus !== "all" &&
            pagination?.refunded_orders_count) ||
          0,
        totalRefundedOrder: pagination?.refunded_orders_count || 0
      });
    }
    if (isCancelled || this.state.orderStatus === "all" || this.state.orderStatus === "cancelled") {
      this.setState({
        totalOrders:
          ((isCancelled || this.state.orderStatus === "cancelled") &&
            this.state.orderStatus !== "all" &&
            pagination?.cancelled_orders_count) ||
          0,
        totalCanceledOrder: pagination?.cancelled_orders_count || 0
      });
    }
    if (this.state.orderStatus === "all") {
      this.setState({
        totalOrders: pagination?.total_count || 0
      });
    }
  };
  getOrders() {
    this.props.showLoader();
    // @ts-ignore
    this.props.displaySpinner();
    let queryParrm = `?per_page=${this.state.rowPerPage}&page=${this.state.page}`;
    const { isPlaced, isDelivered, isRefunded, isCancelled, fromDate, toDate, fromTotal, toTotal } =
      this.state.orderFilters;
    if (this.state.orderStatus === "all") {
      if (isPlaced) {
        queryParrm += "&filter[statuses][]=placed";
      }
      if (isDelivered) {
        queryParrm += "&filter[statuses][]=delivered";
      }
      if (isRefunded) {
        queryParrm += "&filter[statuses][]=refunded";
      }
      if (isCancelled) {
        queryParrm += "&filter[statuses][]=cancelled";
      }
    } else {
      queryParrm += `&filter[statuses][]=${this.state.orderStatus}`;
    }

    if (!!fromDate) {
      queryParrm += `&filter[from_date]=${moment(fromDate).format("DD/MM/YYYY")}`;
    }
    if (!!toDate) {
      queryParrm += `&filter[to_date]=${moment(toDate).format("DD/MM/YYYY")}`;
    }
    if (fromTotal) {
      queryParrm += `&filter[from_amount]=${fromTotal}`;
    }
    if (toTotal) {
      queryParrm += `&filter[to_amount]=${toTotal}`;
    }

    if (this.state.searchValue) {
      queryParrm += `&term=${this.state.searchValue}`;
    }

    const getValidationsMsg = this.generateRequestMessage(
      `${configJSON.orderApiEndpoint}${queryParrm}`,
      configJSON.validationApiMethodType,
      { "content-type": undefined }
    );
    this.ordersDataApiCallId = getValidationsMsg.messageId;
    this.setState({
      queryParrm
    });
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  downloadCSVReport = () => {
    this.props.showLoader();
    const message = this.generateRequestMessage(
      configJSON.downloadCSVReportApiEndpoint,
      configJSON.downloadCSVReportApiMethodType
    );
    this.downloadordersDataApiCallId = message.messageId;
    runEngine.sendMessage(message.id, message);
  };

  postBulkDelete = async (itemIds: any) => {
    this.props.showLoader();
    const requestMessage = this.generateRequestMessage(
      configJSON.postBulkDeleteApiEndpoint,
      configJSON.postBulkDeleteApiMethodType
    );
    let body = {
      action_type: "DELETE",
      resource: "order",
      ids: itemIds
    };
    this.postBulkDeleteCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleOrderStatusChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    this.setState(
      {
        orderStatus: newValue,
        isFirstLoad: false,
        page: 1
      },
      () => {
        this.getOrders();
      }
    );
  };

  setFilter = (orderFilters: any) => {
    this.setState(
      {
        orderFilters: orderFilters,
        isFirstLoad: false
      },
      () => {
        this.getOrders();
      }
    );
  };

  handlePageChange = (page: number) => {
    this.setState({ page }, () => {
      this.getOrders();
    });
  };

  handleSearch = (searchQuery: any) => {
    this.setState({ searchValue: searchQuery, page: 1, isFirstLoad: false }, () => {
      this.getOrders();
    });
  };

  handleRemoveSearchValue = () => {
    this.setState(
      {
        searchValue: "",
        page: 1,
        isFirstLoad: false
      },
      () => {
        this.getOrders();
      }
    );
  };

  handlePopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({
      anchorEl: event.currentTarget
    });
  };
  handlePopoverClose = () => {
    this.setState({
      anchorEl: null
    });
  };

  handleEditClick = (itemData: any) => {
    this.props.history.push(`${configJSON.orderDetailURL}/${itemData.id}`);
  };
  handleDownloadClick = (itemData: any) => {
    window.open(itemData);
  };
}

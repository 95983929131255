import React from "react";
import { TextField, InputAdornment } from "@material-ui/core";
import ColorPicker from "material-ui-color-picker";
import { AppSettingsFormController } from "./AppSettingsFormController";
import withHeaderActionBar from "../../../studio-store-ecommerce-components/src/HOC/withHeadeActionBar.Web";
import { withDialog } from "../../../studio-store-ecommerce-components/src/Dialog/withDialog";
import withLoader from "../../../studio-store-ecommerce-components/src/HOC/withBrandingSpinner.web";

const configJSON = require("../config.js");

const colorPickerIcon = require("../assets/colorpicker.svg");

export class AppSettingsForm extends AppSettingsFormController {
  render() {
    const { primaryColor, appName, androidAppLink, iosAppLink } = this.state;

    return (
      <>
        <div className="store-detail-container address-container justify-content-between">
          <label className="label-store-details">
            {configJSON.primaryAppColor}
          </label>
          <div>
            <ColorPicker
              disabled
              variant="standard"
              value={primaryColor}
              onChange={this.handleCustomPrimaryChange}
              name="primaryColor"
              InputProps={{
                style: {
                  width: "0px",
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <img className="icon-color-picker" src={colorPickerIcon} />
                    <section
                      style={{
                        background: primaryColor,
                        height: "35px",
                        width: "35px",
                        borderRadius: "50%",
                        marginLeft: "8px",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        <div className="store-detail-container address-container justify-content-between">
          <label className="label-store-details">
            {configJSON.deepLinkingConfiguration}
          </label>
          {renderTextField(
            configJSON.appNameLabel,
            "appName",
            appName,
            this.handleTextFieldChange
          )}
          {renderTextField(
            configJSON.googlePlayStoreURLLabel,
            "androidAppLink",
            androidAppLink,
            this.handleTextFieldChange,
            150
          )}
          {renderTextField(
            configJSON.appStoreURLLabel,
            "iosAppLink",
            iosAppLink,
            this.handleTextFieldChange,
            150
          )}
        </div>
      </>
    );
  }
}

const renderTextField = (
  label: any,
  name: any,
  value: any,
  onChange: any,
  limit: any = 51
) => {
  return (
    <div className="d-flex flex-column" style={{ paddingBottom: 24 }}>
      <label className="input-label">{label}</label>
      <TextField
        InputProps={{
          style: {
            width: "100%",
            height: 48,
            color: "#3C3E49",
            fontFamily: "Rubik",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: 14,
          },
        }}
        name={name}
        value={value || ""}
        placeholder={label}
        onChange={(event) => onChange(event, name, limit)}
        variant="outlined"
      />
    </div>
  );
};

export default withDialog(withHeaderActionBar(withLoader(AppSettingsForm)));
